import "vite/modulepreload-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import {
  ThemeProvider,
  ActionSheetProvider,
  PlatformProvider,
} from "@smartrent/ui";

import "./base.css";
import { ErrorBoundary } from "@/shared/ErrorBoundary";
import { FullScreenError } from "@/shared/FullScreenError";
import { createQueryClient } from "@/queryClient";

const reactQueryClient = createQueryClient();

// Mounts the React app to the corresponding DOM element.
// This is the entry point for the all bundles.
// Add any global components here.
export function createBundle(name: string, Bundle: React.FC) {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider initialMode="light">
        <PlatformProvider>
          <ErrorBoundary
            fallback={
              <FullScreenError
                title="Unexpected Error"
                message="We're sorry but we had trouble loading this page. Please try again."
              />
            }
          >
            <GestureHandlerRootView>
              <QueryClientProvider client={reactQueryClient}>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
                <ActionSheetProvider>
                  <Router>
                    <Bundle />
                  </Router>
                </ActionSheetProvider>
              </QueryClientProvider>
            </GestureHandlerRootView>
          </ErrorBoundary>
        </PlatformProvider>
      </ThemeProvider>
    </React.StrictMode>,
    document.querySelector(`[data-react-bundle="${name}"]`) as HTMLElement
  );
}
